import { getStrapiImage } from '@/lib/api'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PlaceIcon from '@mui/icons-material/Place'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTimer } from 'react-timer-hook'
import PopUp from '../popUp/PopUp'

const Section = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 0),
  },
  position: 'relative',
}))

const Banner: React.FC<Props<Banniere>> = ({ data }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: new Date(data?.countdown),
    onExpire: () => console.warn('onExpire called'),
  })

  return (
    <Section>
      <Container>
        <PopUp open={open} handleClose={handleClose} />
        <Grid
          container
          spacing={{ xs: 4, sm: 6, md: 8 }}
          sx={{
            minHeight: '100vh',
            zIndex: 1,
            position: 'relative',
          }}
          alignItems="center"
        >
          <Grid
            item
            container
            lg={6}
            xs={12}
            alignItems="center"
            sx={{ mt: { xs: 2, md: 6, lg: 2 } }}
          >
            <Stack sx={{ m: 'auto' }}>
              <Typography variant="h6" color="secondary">
                {data?.titre1}
              </Typography>
              <Typography
                variant="h3"
                component="h1"
                color="text.primary"
                fontWeight={700}
                gutterBottom
              >
                {data?.titre2}
              </Typography>
              <Typography
                variant="h5"
                component="h1"
                color="text.primary"
                gutterBottom
              >
                {data?.titre3}
              </Typography>
              <Typography color="text.primary" gutterBottom>
                {data?.description}
              </Typography>
              <Stack
                spacing={{ xs: 1, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ py: { xs: 4, md: 6 } }}
              >
                <div>
                  <Button
                    variant="contained"
                    sx={{ px: 4, py: 1, borderRadius: 2 }}
                    disableElevation
                    {...(data?.soumettre?.lien
                      ? {
                          component: 'a',
                          href: data?.soumettre?.lien,
                          target: '_blank',
                          rel: 'noreferrer',
                        }
                      : {
                          onClick: handleOpen,
                        })}
                  >
                    {data?.soumettre?.texte}
                  </Button>
                </div>
                <Link href={data?.contact?.lien || '/'} passHref>
                  <Button variant="outlined" component="a" disableElevation>
                    {data?.contact?.texte}
                  </Button>
                </Link>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
                <Stack>
                  <Stack spacing={2} direction="row">
                    <CalendarMonthIcon />
                    <Typography fontWeight={700}>{data?.date}</Typography>
                  </Stack>
                  <Stack spacing={2} direction="row">
                    <PlaceIcon />
                    <Typography fontWeight={700}>{data?.lieu}</Typography>
                  </Stack>
                </Stack>
                {isRunning && (
                  <Stack direction="row" spacing={2}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        border: '2px dashed black',
                      }}
                    >
                      <Typography variant="h6">
                        {days < 10 ? `0${days}` : days}
                      </Typography>
                    </Stack>

                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        border: '2px dashed black',
                      }}
                    >
                      <Typography variant="h6">
                        {hours < 10 ? `0${hours}` : hours}
                      </Typography>
                    </Stack>

                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        border: '2px dashed black',
                      }}
                    >
                      <Typography variant="h6">
                        {minutes < 10 ? `0${minutes}` : minutes}
                      </Typography>
                    </Stack>

                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        border: '2px dashed black',
                      }}
                    >
                      <Typography variant="h6">
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ zIndex: 2 }}>
            <Stack sx={{ width: 1, height: 500, position: 'relative' }}>
              <Stack
                sx={{
                  width: '85%',
                  height: 300,
                  position: 'relative',
                  borderRadius: 6,
                  overflow: 'hidden',
                }}
              >
                <Image
                  alt="Updev Challenge"
                  src={getStrapiImage(data.media1)}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  // placeholder="blur"
                  // blurDataURL={getStrapiImagePlaceholder(data.media1)}
                  priority
                />
              </Stack>
              <Stack
                alignItems="center"
                alignSelf="flex-end"
                justifyContent="center"
                sx={{
                  width: { xs: '90%', md: '60%' },
                  height: 300,
                  position: 'relative',
                  mt: '-100px',
                  borderRadius: 6,
                  overflow: 'hidden',
                }}
              >
                <Image
                  alt="Updev Challenge"
                  src={getStrapiImage(data.media2)}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  // placeholder="blur"
                  // blurDataURL={getStrapiImagePlaceholder(data.media2)}
                  priority
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default Banner
